/*!
 * languageSelector.js
 * Created by Santiago Prada
 * Copyright © 2016 IBM. All rights reserved.
 */


var LanguageSelector = function(parentView) {

    var that = this;

    var openClass = 'open';

    this.init = function() {

        this.parentView = parentView;
        this.menuContainers = $(".selection-container", this.parentView);
        this.selected = $(".selected-option", this.parentView);
        this.component = $("#language-selector-component", this.parentView);
        this.subMenus = $(".sub-menu", this.parentView);

        this.component.on('touchstart', function(e) {
            that.menuContainers.toggleClass(openClass);
            e.stopPropagation();
        });

        this.component.hover(function() {
            that.menuContainers.addClass(openClass);
        });

        this.component.mouseleave(function() {
            that.menuContainers.removeClass(openClass);
        });

        this.selected.click(function(e) {
            e.preventDefault();
        });

    };

    this.init();
};
